<template>
  <b-card no-body class="card-browser-states">
    <b-card-header>
      <div>
        <b-card-title>Upcoming Birthday</b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="card-height">

      <div v-for="(birthday) in data.recentBirthdys" :key="birthday.customerId" class="browser-states">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-img width="50px" :src="convertImage(birthday.profilePhoto)" alt="browser img" />
          </b-media-aside>
          <b-media-body>
            <h5 class="align-self-center my-auto">
              {{ birthday.customerName }}
            </h5>
            <p class="mobileAndDate align-self-center my-auto">
              {{ birthday.mobileNo }}<span class="align-self-center my-auto">
              ({{ convertTimeStampToDate(birthday.birthdayDate)+')' }}
            </span>
            </p>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <b-button size="sm">Send Wishes</b-button>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BButton
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import moment from 'moment'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg, BButton
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      convertImage(photo) {
            if (photo == null) {
              return require('@/assets/images/avatars/placeholderImage.png');
            } else {
                return 'data:image/*;base64,' + photo;
            }

        },
        
        convertTimeStampToDate(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).local().format('DD/MM/YYYY');
            }
        },
      
    }
  },
  created() {
    
  },
}
</script>
<style>
.card-height {
  height: 333px;
  overflow-y: auto;
}
.mobileAndDate{
  font-size: 10px;
}
</style>
