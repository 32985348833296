<template>
  <section id="dashboard-admin">
    <b-row class="match-height">

      <!-- Total Sales -->
      <b-col xl="4" md="6">
        <dashboard-medal :data="data" />
      </b-col>

      <!-- <b-col> -->
      <!-- TotalCustomers -->
      <b-col xl="4" md="6">
        <b-card title="Total">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="44" variant="warning">
                    <feather-icon size="20" icon="UserPlusIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Customers
                  </h4>
                  <h6 class="font-weight-bolder mb-0">
                    {{ data.totalCustomer }}
                  </h6>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Total Active Customers -->
      <b-col xl="4" md="6">
        <b-card title="Total">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="44" variant="success">
                    <feather-icon size="20" icon="UserCheckIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Active Customers
                  </h4>
                  <h6 class="font-weight-bolder mb-0">
                    {{ data.activeCustomer }}
                  </h6>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Total Wallet Balance -->
      <b-col xl="4" md="6">
        <b-card title="Total">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="44" variant="primary">
                    <feather-icon size="20" icon="BriefcaseIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Wallet Balance
                  </h4>
                  <h6 class="font-weight-bolder mb-0">
                    ₹{{ data.totalWalletFund }}
                  </h6>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Total Employees -->
      <b-col xl="4" md="6">
        <b-card title="Total">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="44" variant="secondary">
                    <feather-icon size="20" icon="UsersIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Employees
                  </h4>
                  <h6 class="font-weight-bolder mb-0">
                    {{ data.totalEmployees }}
                  </h6>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Total Employees -->
      <b-col xl="4" md="6">
        <b-card title="Total">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="44" variant="dark">
                    <feather-icon size="20" icon="ShoppingBagIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Vendors
                  </h4>
                  <h6 class="font-weight-bolder mb-0">
                    {{ data.totalVendors }}
                  </h6>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- </b-col> -->

      <!-- Statistics -->
      <b-col xl="12" md="6">
        <dashboard-statistics :data="data" />
      </b-col>
    </b-row>

    <b-row class="match-height">

      <!-- Order Service Wise Report Card -->
      <b-col lg="6" v-if="orderTotalcategories.length > 0">
        <dashboard-order-service-wise-report :orderTotalcategories="orderTotalcategories"
          :orderTotalseries="orderTotalseries" />
      </b-col>
      <!--/ order Service Wise Report Card -->

      <!-- Pending Order Service Wise Report Card -->
      <b-col lg="6" v-if="orderTotalcategories.length > 0">
        <dashboard-pending-order-service-wise-report :orderTotalcategories="orderTotalcategories"
          :completePendingOrderseries="completePendingOrderseries" />
      </b-col>
      <!--/ Pending order Service Wise Report Card -->

      <!-- Order Revenue Report Card -->
      <b-col lg="12" v-if="monthNameCategories.length > 0">
        <dashboard-order-revenue-report :totalRevenueAmount="totalRevenueAmount"
          :monthNameCategories="monthNameCategories" :revenuByOrderReportSeries="revenuByOrderReportSeries" />
      </b-col>
      <!--/ order Revenue Report Card -->
    </b-row>
    <b-row class="match-height">

      <!-- Account Report Card -->
      <b-col lg="8" md="6" v-if="monthNameCategories.length>0">
        <dashboard-account-report :accountReportSeries="accountReportSeries" :monthNameCategories="monthNameCategories" :accountReportTotalPayment="accountReportTotalPayment" :accountReportTotalReceipt="accountReportTotalReceipt" />
      </b-col>
      <!--/ Account Report Card -->

      <!-- Vendors Overview Card -->
      <b-col lg="4" md="6">
        <dashboard-vendors />
      </b-col>
      <!--/ Vendors Overview Card -->

    </b-row>

    <b-row class="match-height">


      <!-- Transaction Card -->
      <b-col lg="4" md="6">
        <dashboard-transactions :data="data" />
      </b-col>
      <!--/ Transaction Card -->


      <!-- Birthday Card -->
      <b-col lg="4" md="6">
        <dashboard-birthday :data="data" />
      </b-col>
      <!--/ Birthday Card -->

      <!-- Anniversary Card -->
      <b-col lg="4" md="6">
        <dashboard-anniverary :data="data" />
      </b-col>
      <!--/ Anniversary Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BMedia, BMediaAside, BAvatar, BMediaBody } from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
import DashboardMedal from './DashboardMedal.vue'
import DashboardStatistics from './DashboardStatistics.vue'


import DashboardOrderServiceWiseReport from './DashboardOrderServiceWiseReport.vue'
import DashboardPendingOrderServiceWiseReport from './DashboardPendingOrderServiceWiseReport.vue'

import DashboardOrderRevenueReport from './DashboardOrderRevenueReport.vue'

import DashboardAccountReport from './DashboardAccountReport.vue'
import DashboardVendors from './DashboardVendors.vue'



import DashboardTransactions from './DashboardTransactions.vue'
import DashboardBirthday from './DashboardBirthday.vue'
import DashboardAnniverary from './DashboardAnniversary.vue'

import api from '@/store/api'

export default {
  components: {
    BRow,
    BCol, BCard, BMedia, BMediaAside, BAvatar, BMediaBody,

    DashboardMedal,
    DashboardStatistics,

    DashboardOrderServiceWiseReport,
    DashboardPendingOrderServiceWiseReport,
    DashboardOrderRevenueReport,

    DashboardAccountReport,
    DashboardVendors,

    DashboardTransactions,
    DashboardBirthday,
    DashboardAnniverary
  },
  data() {
    return {
      data: {},
      orderTotalseries: [
        {
          name: 'Total Order',
          data: [],
        },
      ],
      orderTotalcategories: [],

      completePendingOrderseries: [
        {
          name: 'Completed',
          data: [],
        },
        {
          name: 'Pending',
          data: [],
        },
      ],

      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      revenuByOrderReportSeries: [
        {
          name: 'Revenue Order',
          data: [],
        }
      ],
      totalRevenueAmount: 0,
      monthNameCategories: [],


      accountReportTotalPayment: 0,
      accountReportTotalReceipt: 0,
      accountReportSeries: [
        {
          name: 'Receipt',
          data: [],
        },
        {
          name: 'Payment',
          data: [],
        },
      ],
    }
  },
  methods: {
    convertMonthNumberToName(number) {
      return this.months[number - 1];
    },
    getDashboardData() {
      let self = this;

      var axios = require('axios');
      var data = '';
      axios(api.getApi('get', '/users/getDaskboardData', data))
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          self.data = response.data;


          // Total Order / Pending / Completed Order Series
          for (let index = 0; index < response.data.orderServiceWiseReport.length; index++) {
            self.orderTotalseries[0].data.push(response.data.orderServiceWiseReport[index].totalOrder);
            self.completePendingOrderseries[0].data.push(response.data.orderServiceWiseReport[index].completeOrders);
            self.completePendingOrderseries[1].data.push('-' + response.data.orderServiceWiseReport[index].pendingOrders);
          }

          // Total Order / Pending / Completed Order Categories
          for (let index = 0; index < response.data.orderServiceWiseReport.length; index++) {
            self.orderTotalcategories.push(response.data.orderServiceWiseReport[index].serviceName);
          }


          // Order Revenue 

          for (let index = 0; index < response.data.revenuByOrderReport.length; index++) {
            self.revenuByOrderReportSeries[0].data.push(response.data.revenuByOrderReport[index].totalRevenu);
            self.totalRevenueAmount += response.data.revenuByOrderReport[index].totalRevenu
          }
          //  Order Month Name Categories
          for (let index = 0; index < response.data.revenuByOrderReport.length; index++) {
            self.monthNameCategories.push(self.convertMonthNumberToName(response.data.revenuByOrderReport[index].monthName));
          }

          //Account Report Payment Receipt
          for (let index = 0; index < response.data.accountReport.length; index++) {
            self.accountReportSeries[0].data.push(response.data.accountReport[index].totalReceipt);
            self.accountReportSeries[1].data.push('-' + response.data.accountReport[index].totalPayment);

            self.accountReportTotalReceipt += response.data.accountReport[index].totalReceipt;
            self.accountReportTotalPayment += response.data.accountReport[index].totalPayment;

          }
          //Account Report Month Category
          for (let index = 0; index < response.data.accountReport.length; index++) {
            self.monthNameCategories.push(self.convertMonthNumberToName(response.data.accountReport[index].monthName));
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data

    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     const userData = getUserData()
    //     this.data.congratulations.name = this.$store.state.name.split(' ')[1] || this.$store.state.userName
    //   })

    this.getDashboardData();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
