<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Order Statistics</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>

        <!-- Orders -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="ShoppingCartIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.totalOrders }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Completed Orders -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="CodesandboxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.completeOrders }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Completed
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Pending Orders -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="BoxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.pendingOrders }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Pending
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        

        <!-- Total Default Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="CrosshairIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalDefultAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Expected Revenue
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Net Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="CreditCardIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalNetAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Revenue
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Amt Less Fro Default Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="ColumnsIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalAmountLessFromDefaultAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Default Price Adjustment
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Gross Amt -->
        <!-- <b-col xl="2" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-secondary"
              >
                <feather-icon
                  size="24"
                  icon="CodesandboxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.totalGrossAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Gross Amount
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col> -->

        <!-- Discount Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="DatabaseIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.discountAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Discount
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Tax Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-dark"
              >
                <feather-icon
                  size="24"
                  icon="TagIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalTaxAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Tax
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        

        <!-- Due Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="CodepenIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalDueAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Due
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Payed Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="FilePlusIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalPayedAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Received
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Adjustment Amount -->
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0 mt-1">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-dark"
              >
                <feather-icon
                  size="24"
                  icon="GridIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ₹{{ data.totalAdjustmentAmount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Order Adjustment
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
