<template>
  <b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="8" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Account Report
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span>Receipt</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Payment</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts id="revenue-report-chart" type="bar" height="230" :options="revenueReport.chartOptions"
          :series="accountReportSeries" />
      </b-col>

      <b-col md="4" class="budget-wrapper" >
        <b-row>
          <b-col>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="44" variant="success">
                  <feather-icon size="20" icon="BriefcaseIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Receipt
                </h4>
                <h6 class="font-weight-bolder mb-0">
                  ₹{{accountReportTotalReceipt}}
                </h6>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="44" variant="primary">
                  <feather-icon size="20" icon="CreditCardIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Payment
                </h4>
                <h6 class="font-weight-bolder mb-0">
                  ₹{{accountReportTotalPayment}}
                </h6>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BMedia, BAvatar, BMediaBody, BMediaAside,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import api from '@/store/api'

export default {
  components: {
    VueApexCharts, BMedia, BAvatar, BMediaBody, BMediaAside,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    accountReportSeries:Array,
    monthNameCategories:Array,
    accountReportTotalPayment:Number,
    accountReportTotalReceipt:Number
  },
  data() {
    return {
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: this.monthNameCategories,
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
          },
          colors: [$themeColors.success, $themeColors.primary],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
      months:['Jan','Feb','Mar', 'Apr', 'May', 'Jun', "Jul", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    }
  },
  // methods: {
  //   convertMonthNumberToName(number){
  //     return this.months[number-1];
  //   },
  //   getCategories() {
  //     for (let index = 0; index < this.data.accountReport.length; index++) {
  //       this.revenueReport.chartOptions.xaxis.categories.push(this.convertMonthNumberToName(this.data.accountReport[index].monthName));
  //     }
  //   },
  //   getSeries() {
  //     let self=this;
  //     for (let index = 0; index < this.data.accountReport.length; index++) {
  //       self.series[0].data.push(this.data.accountReport[index].totalReceipt);
  //       self.series[1].data.push('-'+this.data.accountReport[index].totalPayment);
        
  //       self.totalReceipt+=this.data.accountReport[index].totalReceipt;
  //       self.totalPayment+=this.data.accountReport[index].totalPayment;

  //     }
  //   },
  //   getDashboardData() {
  //     let self = this;
      
  //     var axios = require('axios');
  //     var data = '';
  //     axios(api.getApi('get', '/users/getDaskboardData', data))
  //       .then(function (response) {
  //         console.log(JSON.stringify(response.data));
  //         self.data = response.data;

  //          self.getSeries();
  //          self.getCategories();
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   },
  // },
  // created() {
  //   this.getDashboardData();
  // }
}
</script>
<style>
  .text-top{
    top: 25%;
    bottom: 50%;
  }
</style>