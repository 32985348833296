<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Transactions</b-card-title>
    </b-card-header>

    <b-card-body>
      <div class="transaction-item">

        <b-col md="7">
          <h6 class="transaction-title">
            <b>Payment Mode</b>
          </h6>
        </b-col>
        <b-col md="3">
          <div class="font-weight-bolder text-success">
            In
          </div>
        </b-col>
        <b-col md="2">
          <div class="font-weight-bolder text-danger">
            Out
          </div>
        </b-col>
      </div>
    </b-card-body>


    <b-card-body class="header-margin-top">
      <div v-for="transaction in data.transactionByPaymentModeReport" :key="transaction.paymentMode"
        class="transaction-item">
        <b-col md="6">
          <h6 class="transaction-title titleCurrencyName">
            {{ transaction.paymentMode }}
          </h6>
        </b-col>
        <b-col md="3" class="text-center">
          <div class="font-weight-bolder titleCurrencyName text-success" v-if="transaction.totalPaymentIn>0">
            ₹{{ transaction.totalPaymentIn }}
          </div>
        </b-col>
        <b-col md="3" class="text-center">
          <div class="font-weight-bolder titleCurrencyName text-danger" v-if="transaction.totalPaymentOut>0">
            ₹{{ transaction.totalPaymentOut }}
          </div>
        </b-col>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCol, BRow
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCol, BRow,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style>
.header-margin-top {
  margin-top: -33px;
}
.titleCurrencyName{
  font-size:12px;
}
</style>
