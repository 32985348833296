<template>
  <b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="12" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Revenue By Order Report
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span class="mr-2">Revenue</span>
              <h4 class="font-weight-bolder mb-0">₹{{totalRevenueAmount}}</h4>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts id="revenue-report-chart" type="bar" height="230" :options="revenueReport.chartOptions"
          :series="revenuByOrderReportSeries" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BMedia, BMediaBody, BMediaAside, BAvatar
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import api from '@/store/api'

export default {
  components: {
    VueApexCharts, BMedia, BMediaBody, BMediaAside, BAvatar,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    monthNameCategories:Array,
    revenuByOrderReportSeries:Array,
    totalRevenueAmount:Number
  },
  data() {
    return {
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: this.monthNameCategories,
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
          },
          colors: [$themeColors.success, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
    }
  },
}
</script>
<style>
.text-top {
  top: 25%;
  bottom: 50%;
}
</style>
